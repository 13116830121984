<script lang="ts" setup> 

    const img = useImage()

    const props = defineProps({ 
        blok: Object 
    })

    const imageCheck = (img:string) => {
        return img.split('.').pop() || ''
    }

    const width = (value:string) => {
        return value.split('/')[5].split('x')[0]
    }

    const height = (value:string) => {
        return value.split('/')[5].split('x')[1]
    }

    const richtext = (value:any) => renderRichText(value) 

    const settings = reactive({
        settings: {
            itemsToShow: 1.5,
            snapAlign: 'center',
        }
    })

    const data = reactive({
        breakpoints: {
            320: {
                itemsToShow: 1.5,
                snapAlign: 'center',
            },
            640: {
                itemsToShow: 2.5,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 3.5,
                snapAlign: 'center',
            },
            1000: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
            1024: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
            1280: {
                itemsToShow: 5,
                snapAlign: 'center',
            },
            1300: {
                itemsToShow: 5,
                snapAlign: 'center',
            },
            1400: {
                itemsToShow: 5,
                snapAlign: 'center',
            },
            1536: {
                itemsToShow: 5,
                snapAlign: 'center',
            },
            1620: {
                itemsToShow: 5,
                snapAlign: 'center',
            },
            1800: {
                itemsToShow: 5,
                snapAlign: 'center',
            }
        }
    })

</script>

<template>
   <div v-editable="blok" class="grid grid-col-1" :class="[blok?.theme || 'dark']">
        <div class="flex flex-col gap-6 lg:gap-8">
      
            <div v-if="blok?.heading || blok?.subheading || blok?.richtext && richtext(blok?.richtext)" class="flex flex-col gap-4 justify-center items-center text-center max-w-[900px] mx-auto">
                <div v-if="blok?.heading || blok?.subheading" class="flex flex-col gap-2 justify-center items-center text-center">
                    <component
                        v-if="blok && blok?.heading" 
                        :is="`headings-${blok?.heading_tag || 'h3'}`" 
                        :value="blok?.heading"
                        :classes="blok?.heading_style || 'h2'" 
                    />
                    <component
                        v-if="blok && blok?.subheading" 
                        :is="`headings-${blok?.subheading_tag || 'h4'}`" 
                        :value="blok?.subheading"
                        :classes="blok?.subheading_style || 'h3'"
                    />
                </div>
                <div v-if="blok?.richtext && richtext(blok?.richtext)" v-html="richtext(blok?.richtext)" class="flex flex-col gap-2 | font-hass-text text-p_mobile md:text-p_tablet lg:text-p_desktop font-normal text-white-50/75 dark:text-nevada-500"/>
            </div>

            <div>
                <Carousel v-bind="settings" :breakpoints="data.breakpoints" :autoplay="3000" :wrap-around="true">
                    <Slide v-for="logo in blok?.logos" :key="logo._uid" v-editable="logo" aria-hidden="false">
                        <div class="carousel__item w-full h-5 flex flex-col justify-center items-center text-center px-2" aria-hidden="false">
                            
                            <template v-if="logo?.image && logo?.image?.filename && imageCheck(logo?.image?.filename) === 'svg'">
                                <NuxtImg
                                    v-if="logo?.image && logo?.image?.filename"
                                    provider="storyblok"
                                    width="160"
                                    height="40"
                                    :src="logo?.image?.filename"
                                    :alt="logo?.image?.alt || logo?.image?.title || '#'" 
                                    :fetchpriority="'auto'"
                                    :decoding="'async'"
                                    :loading="'lazy'"
                                    class="w-auto h-5 m-auto p-0 object-contain"
                                />
                            </template>
                            <template v-else>
                                <NuxtImg
                                    v-if="logo?.image && logo?.image?.filename"
                                    provider="storyblok"
                                    :width="width(logo?.image?.filename)"
                                    :height="height(logo?.image?.filename)" 
                                    :sizes="`xs:100vw sm:${width(logo?.image?.filename)} md:${width(logo?.image?.filename)} narrow:${width(logo?.image?.filename)} lg:${width(logo?.image?.filename)} xl:${width(logo?.image?.filename)} 1xl:${width(logo?.image?.filename)} normal:${width(logo?.image?.filename)} 2xl:${width(logo?.image?.filename)} 3xl:${width(logo?.image?.filename)} wide:${width(logo?.image?.filename)}`"
                                    quality="75"
                                    fit="in"
                                    :placeholder="[width(logo?.image?.filename), height(logo?.image?.filename), 10, 5]"
                                    :src="logo?.image?.filename"
                                    :alt="logo?.image?.alt || logo?.image?.title || '#'" 
                                    :fetchpriority="'auto'"
                                    :decoding="'async'"
                                    :loading="'lazy'"
                                    class="w-auto h-5 m-auto p-0 object-contain"
                                />
                            </template>

                        </div>
                    </Slide>
                </Carousel>
            </div>

            <div v-if="blok?.buttons && blok?.buttons.length > 0" class="flex flex-wrap gap-2 justify-center items-center text-center">
                <StoryblokComponent v-for="button in blok.buttons" :key="button._uid" :blok="button" />
            </div>

        </div>
   </div>
</template>

<style lang="scss">
    .logo-carousel {
        .carousel {
            
            @apply cursor-grab;

            &.is-dragging {
                @apply cursor-grabbing
            }

        }
    }
</style>
